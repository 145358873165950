import { useAuth } from '@yieldstreet/tool-kit';
import { useDiscretionaryFlags } from 'hooks';
import { SIGNUP_BASE_PATH } from '../../../Signup.model';
import { ManagedPortfolioUTMSource, managedPortfolioParamKeys } from './useManagedPortfolioTagging.model';
export const useManagedPortfolioUtmTagging = () => {
  const {
    enableManagedPortfolioSignup
  } = useDiscretionaryFlags();
  const {
    isAuthenticated
  } = useAuth();
  const params = new URLSearchParams({
    [managedPortfolioParamKeys.internal]: ManagedPortfolioUTMSource.ManagedPortfolioNew
  });
  const canRedirect = !isAuthenticated && enableManagedPortfolioSignup;
  return {
    redirectURI: canRedirect && `${SIGNUP_BASE_PATH}?${params.toString()}`
  };
};